import React, {useContext, useEffect, useState} from "react";
import {useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";
import colorConfig from "../../../styles/Theme-settings";
import {LocalizationContext} from "../../../context/localizationContext";
import {TestcaseContext} from "../../../context/testcaseContext";
import {OrgContext} from "../../../context/orgContext";
import APICall from "../../../functional/APIRequests";
import {AuthContext} from "../../../context/authContext";
import {useSnackbar} from "notistack";
import Mobile from "./mobile";
import Tablet from "./tablet";

const TestCaseDetails = (props) => {
    const classes = useStyles();

    const mobile = useMediaQuery("(max-width: 767px)");
    const tablet = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
    const desktop = useMediaQuery("(min-width: 1025px)");

    const {id} = useParams();

    const {getTranslation} = useContext(LocalizationContext);
    const {getOrganizationById, selectedOrg} = useContext(OrgContext);
    const {setLoading, user} = useContext(AuthContext);
    const {
        getTestCase,
        testCaseData,
        userData,
        setUserData,
        closeTestCase,
        setBarcodeInUse,
        checkUserInTestList,
        barcodeInUse
    } = useContext(TestcaseContext);
    const {enqueueSnackbar} = useSnackbar();
    const {
        rawProducts,
        fetchPersonnel,
        fetchReferral,
        identification_documents
    } = useContext(TestcaseContext);

    const ROWS_PER_PAGE = [5, 10, 15, 20];

    const SORT_VALUES = [
        {value: "id", label: "-"},
        {value: "name", label: getTranslation("testcase_details_select_name_label")},
        {value: "phone", label: getTranslation("testcase_details_select_phone_label")},
        {value: "have_been_tested", label: getTranslation("testcase_details_select_tested_label")},
    ];


    const [orgName, setOrgName] = useState("");
    const [usersToTest, setUsersToTest] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
    const [page, setPage] = useState(0);
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [filter, setFilter] = useState({});
    const [sortBy, setSortBy] = useState(SORT_VALUES[0].value);
    const [sortOrder, setSortOrder] = useState("asc");
    const [testCaseReferrals, setTestCaseReferrals] = useState([]);
    const [usersInfo, setUsersInfo] = useState({});
    const [barcodeOpen, setBarcodeOpen] = useState(false);
    const [barcodeIsValid, setBarcodeIsValid] = useState('pending');
    const [expressReferralData, setExpressReferralData] = useState({})
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [expressReferralFormData, setExpressReferralFormData] = useState({})
    const [isScanning, setIsScanning] = useState(false);
    const [saveReferralClicked, setSaveReferralClicked] = useState(false);

    const getPhotoDivStyle = (photo, closed, haveBeenTested) => {
        if (!photo || photo === '') {
            return {
                border: "1px solid rgba(0, 0, 0, 0.37)",
                height: 300,
                position: "relative",
                cursor: `${(closed || haveBeenTested === '1') ? 'default' : 'pointer'}`,
                pointerEvents: `${(closed || haveBeenTested === '1') ? 'none' : 'auto'}`,
            }
        } else {
            return {
                height: 300,
                pointerEvents: `${(closed || haveBeenTested === '1') ? 'none' : 'auto'}`,
                position: "relative",
                //cursor: "pointer",
                backgroundImage: `url('${photo}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center"
            }
        }
    };

    const handleSortChange = (value) => {
        let _sortOrder;
        let _sortBy;
        if (value === sortBy) {
            _sortOrder = sortOrder === "asc" ? "desc" : "asc";
            _sortBy = value;
        } else {
            _sortOrder = "asc";
            _sortBy = value;
            setSortBy(_sortBy);
        }
        setSortOrder(_sortOrder);
        setSortBy(_sortBy);
        // updateUsersToTest(0, rowsPerPage, _sortBy, _sortOrder);
    };


    const handleChangeSortMobile = value => {
        let _sortBy = value;
        let _sortOrder = "asc";
        setSortOrder("asc");
        setSortBy(value);
        // updateUsersToTest(0, rowsPerPage, _sortBy, _sortOrder);
    }


    const handleChangeSortOrderMobile = () => {
        let _sortOrder = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(_sortOrder);
        // updateUsersToTest(0, rowsPerPage, sortBy, _sortOrder)
    }

    const onUserClick = user => {
        //setUserData(user);
        props.history.push(`/testcase/${id}/user/${user.sys_id}`);
    };

    const fetchTestCaseExpressReferrals = (limit = 20, offset = 0) => {
        let form = new FormData();
        form.append("data[limit]", limit);
        form.append("data[offset]", offset);
        setLoading(true);

        APICall({
            url: `customer/${selectedOrg.id}/testcase/${id}/express_referral?data[test_case_id]=${id}&data[limit]=${limit}&data[offset]=${offset}`,
            verb: 'get',
            server: 'api',
            formData: true,
            data: form,
        })
            .then(res => {
                if (res && res.data) {
                    setExpressReferralData(res.data);
                }
                setLoading(false);
            })
            .catch(e => setLoading(false))
    }

    const deleteExpressReferral = (express_referral_id) => {
        setLoading(true);

        APICall({
            url: `customer/${selectedOrg.id}/express_referral/${express_referral_id}/deleteExpressReferral`,
            verb: 'delete',
            server: 'api',
        })
            .then(res => {
                if (res) {
                    fetchTestCaseExpressReferrals();
                }
                setLoading(false);
            })
            .catch(e => setLoading(false))
    }

    const fetchUserInfoExpress = () => {
        // setLoading(true);
        // APICall({
        //   setLoading,
        //   enqueueSnackbar,
        //   url: `customer/${selectedOrg.id}/express_referral/${id}`,
        //   verb: 'get',
        //   server: 'api'
        // })
        //   .then(res => {
        //     if (res.data) {
        //       console.log(res);
        //       // setUserData(prevState => ({
        //       //   ...prevState,
        //       //   have_been_tested: res.data[0].have_been_tested,
        //       //   sampler_check: res.data[0].have_been_tested === "1",
        //       //   ssn: res.data[0].ssn,
        //       //   name: res.data[0].name,
        //       //   phone: res.data[0].phone,
        //       //   email: res.data[0].email
        //       // }))
        //       // fetchReferral(res.data[0].ssn, id);
        //     }
        //   })
        //   .catch(e => setLoading(false))
    }

    const fetchUsersToTest = (reset = false, aPage, aRowsPerPage, aSortBy, aSortOrder) => {
        setLoading(true);

        let _page = aPage !== undefined ? aPage : page;
        let _rowsPerPage = aRowsPerPage || rowsPerPage;
        let _sortBy = aSortBy || sortBy;
        let _sortOrder = aSortOrder || sortOrder;

        let url = `customer/${selectedOrg.id}/testcase/users/to_test?data[limit]=${_rowsPerPage}&data[offset]=${_rowsPerPage * _page}&data[test_case_id]=${id}`;
        let order = _sortOrder === "asc" ? "ASC" : "DESC";

        if (_sortBy === "name") {
            url = url + `&data[sort]=name ${order}`;
        }
        if (_sortBy === "phone") {
            url = url + `&data[sort]=phone ${order}`;
        }
        if (_sortBy === "have_been_tested") {
            url = url + `&data[sort]=have_been_tested ${order}`;
        }

        if (reset) {
            setFilter({});
        } else {
            if (filter.name && filter.name.length > 0) {
                url = url + `&data[name]=${filter.name}`
            }
            if (filter.ssn && filter.ssn.length > 0) {
                url = url + `&data[ssn]=${filter.ssn}`
            }
            if (filter.phone && filter.phone.length > 0) {
                url = url + `&data[phone]=${filter.phone}`
            }
        }

        APICall({
            setLoading,
            enqueueSnackbar,
            url: url,
            verb: 'get',
            server: 'api',
            formData: false
        }).then(res => {
            if (res) {
                setUsersToTest(res);
            }
        });
        setLoading(false);
    };

    const createExpressReferral = () => {
        setSaveReferralClicked(true);
        let form = new FormData();
        form.append("data[test_case_id]", id);
        form.append("data[express_referral_id]", expressReferralFormData.barcode);
        form.append("data[user_id]", user.id);

        const base64String = expressReferralFormData.id_photo;
        const base64Content = base64String.split(';base64,').pop();
        const blob = base64ToBlob(base64Content, 'image/jpeg');

        form.append("file", blob, "image.jpg"); // The key 'file' should match the key expected in $_FILES in your PHP

        APICall({
            setLoading,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/express_referral/create`,
            verb: 'post',
            server: 'api',
            formData: true,
            data: form,
        })
            .then(res => {
                setLoading(false);
                fetchTestCaseExpressReferrals();
                clearFormFields();
                setBarcodeIsValid('pending');
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const closeTest = () => {
        closeTestCase(id)
            .then(() => {
                setLoading(false);
                enqueueSnackbar(getTranslation("testcase_closed_success"), {variant: "success"});
                getTestCase(id);
                setIsPopupOpen(false);
            })
            .catch(() => {
                setLoading(false);
            })
    };

    const updateUsersToTest = (page, rowsPerPage, sortBy, sortOrder) => {
        setPage(page);
        setRowsPerPage(rowsPerPage);
        // fetchUsersToTest(false, page, rowsPerPage, sortBy, sortOrder);
    }

    const checkBarcodeInUse = barcode => {
        setLoading(true);
        APICall({
            setLoading,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/referral/isvalid?referral_id=${barcode}`,
            verb: 'get',
            server: 'api'
        })
            .then(res => {
                if (res.data === 0) {
                    enqueueSnackbar(getTranslation('testcase_barcode_used'), {variant: "error"});
                    setBarcodeInUse(true);
                } else {
                    setBarcodeInUse(false);
                    setBarcodeIsValid('valid');
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            })
    };

    const handleTextChange = e => {
        setExpressReferralFormData({...expressReferralFormData, [e.target.name]: e.target.value})
    }

    const clearFormFields = () => {
        setExpressReferralFormData({});
    }

    // Helper function to convert base64 to Blob
    function base64ToBlob(base64, mimeType) {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: mimeType});
    }

    const handleCaptureIdPhoto = photo => {
        setExpressReferralFormData({...expressReferralFormData, ["id_photo"]: photo})
    }

    const handleCaptureIdPhotoMobile = e => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                setExpressReferralFormData({...expressReferralFormData, ["id_photo"]: reader.result});
            }

            reader.onerror = error => {
                console.log("Error reading file");
            }
        }
    }

    useEffect(() => {
        getTestCase(id, "personal");
        setIsScanning(false);
        setBarcodeInUse(false);
        if (!selectedOrg.consult) {
            fetchPersonnel();
        }
        fetchUserInfoExpress();

        return () => {
            setUserData({});
        };
    }, [])

    useEffect(() => {
        // fetchUsersToTest(false);
        getTestCase(id);
        fetchTestCaseExpressReferrals();
    }, [page, rowsPerPage]);

    /*  useEffect(() => {
        fetchUserInfo();
      }, [testCaseReferrals])*/

    useEffect(() => {
        if (testCaseData.organisation_id && !selectedOrg.consult) {
            getOrganizationById({idOrg: testCaseData.organisation_id})
                .then(res => setOrgName(res.name))
        }
    }, [testCaseData.organisation_id])

    useEffect(() => {
        setBarcodeIsValid('pending');
    }, [id])


    return (
        <>
            {(desktop || tablet) &&
                <Tablet
                    getTranslation={getTranslation}
                    userData={userData}
                    setUserData={setUserData}
                    expressReferralData={expressReferralData}
                    setExpressReferralData={setExpressReferralData}
                    expressReferralFormData={expressReferralFormData}
                    setExpressReferralFormData={setExpressReferralFormData}
                    handleTextChange={handleTextChange}
                    checkBarcodeInUse={checkBarcodeInUse}
                    barcodeInUse={barcodeInUse}
                    barcodeIsValid={barcodeIsValid}
                    barcodeOpen={barcodeOpen}
                    setBarcodeOpen={setBarcodeOpen}
                    deleteExpressReferral={deleteExpressReferral}
                    id={id}
                    testCaseData={testCaseData}
                    testCaseReferrals={testCaseReferrals}
                    orgName={orgName}
                    usersToTest={usersToTest}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    filter={filter}
                    setFilter={setFilter}
                    filterModalOpen={filterModalOpen}
                    setFilterModalOpen={setFilterModalOpen}
                    fetchUsersToTest={updateUsersToTest}
                    createExpressReferral={createExpressReferral}
                    selectedOrg={selectedOrg}
                    closeTestCase={closeTest}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    handleOrderChange={handleSortChange}
                    classes={classes}
                    rowsOptions={ROWS_PER_PAGE}
                    onUserClick={onUserClick}
                    enqueueSnackbar={enqueueSnackbar}
                    usersInfo={usersInfo}
                    getPhotoDivStyle={getPhotoDivStyle}
                    isPopupOpen={isPopupOpen}
                    setIsPopupOpen={setIsPopupOpen}
                    handleCaptureIdPhoto={handleCaptureIdPhoto}
                    setIsScanning={setIsScanning}
                    isScanning={isScanning}
                    saveReferralClicked={saveReferralClicked}
                    {...props} />
            }
            {mobile &&
                <Mobile
                    getTranslation={getTranslation}
                    userData={userData}
                    setUserData={setUserData}
                    expressReferralData={expressReferralData}
                    setExpressReferralData={setExpressReferralData}
                    expressReferralFormData={expressReferralFormData}
                    setExpressReferralFormData={setExpressReferralFormData}
                    handleTextChange={handleTextChange}
                    checkBarcodeInUse={checkBarcodeInUse}
                    barcodeIsValid={barcodeIsValid}
                    barcodeInUse={barcodeInUse}
                    barcodeOpen={barcodeOpen}
                    setBarcodeOpen={setBarcodeOpen}
                    deleteExpressReferral={deleteExpressReferral}
                    id={id}
                    testCaseData={testCaseData}
                    testCaseReferrals={testCaseReferrals}
                    orgName={orgName}
                    usersToTest={usersToTest}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    filter={filter}
                    setFilter={setFilter}
                    filterModalOpen={filterModalOpen}
                    setFilterModalOpen={setFilterModalOpen}
                    fetchUsersToTest={updateUsersToTest}
                    createExpressReferral={createExpressReferral}
                    selectedOrg={selectedOrg}
                    closeTestCase={closeTest}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    handleOrderChange={handleSortChange}
                    classes={classes}
                    rowsOptions={ROWS_PER_PAGE}
                    onUserClick={onUserClick}
                    enqueueSnackbar={enqueueSnackbar}
                    usersInfo={usersInfo}
                    getPhotoDivStyle={getPhotoDivStyle}
                    setIsPopupOpen={setIsPopupOpen}
                    isPopupOpen={isPopupOpen}
                    handleCaptureIdPhoto={handleCaptureIdPhotoMobile}
                    setIsScanning={setIsScanning}
                    isScanning={isScanning}
                    saveReferralClicked={saveReferralClicked}
                    {...props}
                />
            }
        </>
    );
}

const useStyles = makeStyles(theme => ({
    mainContainer: {
        paddingBottom: 30
    },
    row: {
        marginBottom: 20
    },
    backButton: {
        color: colorConfig.lightBlack
    },
    backIcon: {
        fill: colorConfig.lightBlack
    },
    paperMobile: {
        width: "100%",
        padding: 12
    },
    paperHeader: {
        padding: '0px 25px',
        height: 70
    },
    paperContent: {
        height: '100%',
    },
    paper: {
        paddingLeft: 15
    },
    testNrsMobile: {
        display: "flex"
    },
    testNrs: {
        display: "flex",
        justifyContent: "flex-end"
    },
    testsDone: {
        color: colorConfig.green,
        fontSize: "1.1rem"
    },
    totalTests: {
        color: "#bababa",
        fontSize: "1.1rem"
    },
    editContainerMobile: {
        backgroundColor: "#222222",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0"
    },
    editContainerNotSignedMobile: {
        backgroundColor: "#b7b7b7",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0"
    },
    editContainer: {
        backgroundColor: "#222222",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0",
        minHeight: 70,
        cursor: "pointer"
    },
    editorContainerNotSigned: {
        backgroundColor: "#b7b7b7",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0",
        minHeight: 70,
        cursor: "pointer"
    },
    editIcon: {
        fill: "#ffffff",
        width: 28,
        height: 28
    },
    tested: {
        height: 22,
        width: 22,
        backgroundColor: colorConfig.green,
        borderRadius: "50%",
        display: "inline-block"
    },
    notTested: {
        height: 22,
        width: 22,
        backgroundColor: "#d8102e",
        borderRadius: "50%",
        display: "inline-block"
    },
    status: {
        textTransform: "uppercase",
        color: colorConfig.green
    },
    tableHeaderText: {
        fontWeight: "bold"
    },
    bottomButtonsWrap:~ {
        marginTop: 40
    },
    buttonWrap: {
        textAlign: "center"
    },
    button: {
        textTransform: "uppercase",
        paddingTop: 15,
        paddingBottom: 15,
        fontWeight: "bold",
        minWidth: "200px",
        maxWidth: "200px"
    },
    clearIcon: {
        fill: colorConfig.textGray,
        position: "absolute",
        top: 25,
        right: -3,
        width: 20,
        height: 20,
        cursor: "pointer"
    },
    searchPlaceholder: {
        '&::placeholder': {
            fontSize: 14,
        },
    },
    filterTextFieldMobile: {
        paddingLeft: 0
    },
    clearIconMobile: {
        fill: colorConfig.textGray,
        position: "absolute",
        top: 25,
        right: 7,
        width: 20,
        height: 20,
        cursor: "pointer"
    },
    filterButton: {
        borderRadius: 0,
        height: 35
    },
    filterButtonWrapper: {
        textAlign: "right",
        marginTop: 20,
        marginBottom: 10
    },
    sortableHeader: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    },
    mobileSortIcon: {
        cursor: "pointer",
        marginLeft: 10
    },
    resetFilterButtonDesktop: {
        marginTop: 5,
        marginLeft: 15
    },
    resetFilterButtonMobile: {
        marginRight: 15
    },
    notchedOutlineMobile: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: "none"
    },
    notchedOutline: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    barcodeInputWrap: {
        display: "flex"
    },
    paddingLeft: {
        paddingLeft: "0px !important"
    },
    barcodeTextInput: {
        paddingRight: 0
    },
    barcodeTextInputMobile: {
        width: "96%",
      paddingRight: 0
    },
    textFieldInputLabel: {
        color: `${colorConfig.textGray} !important`,
    },
    imageLabel: {
        color: `${colorConfig.textGray} !important`,
        fontSize: 12,
        marginBottom: 4,
        textAlign: "center"
    },
    photoLabel: {
        color: "rgba(0, 0, 0, 0.23)",
        paddingLeft: 10,
        paddingTop: 10
    },
    photoLabelMobile: {
        color: "rgba(0, 0, 0, 0.23)",
        paddingLeft: 5,
        paddingTop: 0
    },
    cameraIcon: {
        fill: colorConfig.green,
        height: 70,
        width: 70,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    cameraIconMobile: {
        fill: colorConfig.green,
        height: 50,
        width: 50,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -30%)"
    },
    referralBox: {
        borderRadius: 5,
        border: '1px solid black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 8
    },
    deleteIcon: {
        fill: 'black',
        height: 25,
        width: 25,
        cursor: "pointer"
    },
    qrCodeControlsWrapper: {
        marginTop: 4,
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "end"
    },
    camerasList: {
        position: "absolute",
        background: "#f5f5f5",
        zIndex: 2,
        top: 29,
        border: "1px solid #CDCDCD",
        borderRadius: "0 0px 5px 5px",
        width: "100%"
    },
    cameraListItem: {
        padding: "14px 14px",
        fontSize: 14,
    },
    changeCameraBtn: {
        background: "transparent",
        boxShadow: "none",
        padding: 0,

        "&:hover": {
            background: "transparent",
            boxShadow: "none"
        },
        "&:active": {
            background: "transparent",
            boxShadow: "none"
        }
    },
    changeCameraBtnText: {
        fontSize: 13,
        color: "#333333"
    }
}));

export default TestCaseDetails;
