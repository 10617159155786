import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Grid, MenuItem, Paper, Select, Typography, TextField, Box} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Webcam from "../../../components/webcam";
import {TestcaseContext} from "../../../context/testcaseContext";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import {FilterList} from "@material-ui/icons";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import LastPageIcon from "@material-ui/icons/LastPage";
import colorConfig from "../../../styles/Theme-settings";
import {apiServer} from "../../../functional/generateEnvUrls";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import ConfirmDeletePopup from './confirmDeletePopup';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
//import BarcodeScanner from "../../../components/barcodeScanner";
import CameraComp from "../../../components/cameraComp";
import BarcodeReader from "../../../components/BarcodeReader";
import BarcodeScanner from "../../../components/html5QRCode";
import Html5QrcodePlugin from "../../../components/Html5QrcodePlugin";
import FlipCameraIosIcon from "@material-ui/icons/FlipCameraIos";
import {toScrollTop} from "../../../functional/etc";

const Mobile = props => {
    const {
        getTranslation,
        userData,
        expressReferralData,
        deleteExpressReferral,
        setExpressReferralData,
        expressReferralFormData,
        setExpressReferralFormData,
        handleTextChange,
        checkBarcodeInUse,
        barcodeInUse,
        barcodeIsValid,
        barcodeOpen,
        setBarcodeOpen,
        getPhotoDivStyle,
        id,
        usersToTest,
        testCaseData,
        orgName,
        setUserData,
        rowsPerPage,
        page,
        filter,
        setFilter,
        filterModalOpen,
        setFilterModalOpen,
        fetchUsersToTest,
        createExpressReferral,
        closeTestCase,
        selectedOrg,
        sortBy,
        sortOrder,
        handleOrderChange,
        classes,
        rowsOptions,
        onUserClick,
        enqueueSnackbar,
        usersInfo,
        setIsPopupOpen,
        isPopupOpen,
        handleCaptureIdPhoto,
        isScanning,
        setIsScanning,
        saveReferralClicked,
    } = props;

    const fileInputRef = useRef(null);

    const {cameras, setSelectedCamera} = useContext(TestcaseContext);

    const [showCameraList, setShowCameraList] = useState(false);

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    }
    const openPDF = () => {
        if (testCaseData.tests_done > 0) {
            //let url = `${process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/testcase/${id}/pdf`;
            let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/testcase/${id}/pdf`;
            window.open(url, "_blank");
        } else {
            enqueueSnackbar(getTranslation("testcase_no_tests_to_print"), {variant: "error"});
        }
    }

    const resetFilter = () => {
        setFilter({})
        // fetchUsersToTest(true, 0, props.rowsPerPage);
    };

    const handleDelete = (referral) => {
        deleteExpressReferral(referral.express_referral_id);
    };

    const onNewScanResult = (decodedText, decodedResult) => {
        try {
            setExpressReferralFormData({...expressReferralFormData, ["barcode"]: decodedText});
            console.log("here");
            console.log(decodedText)
            setIsScanning(false);
            checkBarcodeInUse();
        }
        catch (e) {
            console.log(e)
        }
    };

    const handleScanningOpen = () => {
        setIsScanning(true);
    }

    const autoClicks = () => {
        let requestPermissionBtn = document.getElementById("html5-qrcode-button-camera-permission");
        let startScanningBtn = document.getElementById("html5-qrcode-button-camera-start");
        let stopScanningBtn = document.getElementById("html5-qrcode-button-camera-stop");

        if (isScanning) {
            if (requestPermissionBtn) {
                requestPermissionBtn.click();
            } else if (startScanningBtn) {
                startScanningBtn.click();
            }
        } else {
            if (stopScanningBtn)
                stopScanningBtn.click();
        }
    }

    const handleShowCameraListClick = () => {
        setShowCameraList(!showCameraList);
        let stopScanningBtn = document.getElementById("html5-qrcode-button-camera-stop");
        if(stopScanningBtn) {
            stopScanningBtn.click();
        }
    }

    const handleSelectBarcodeCamera = (camera) => {
        setSelectedCamera(camera);
        setShowCameraList(false);

        const stopScanningBtn = document.getElementById("html5-qrcode-button-camera-stop");
        if (stopScanningBtn) {
            stopScanningBtn.click();
        }

        toScrollTop();
    }

    useEffect(() => {
        setTimeout(autoClicks, 1200);
    }, [isScanning]);

    return (
        <>
            {/* {isScanning &&
                <BarcodeScanner
                    checkBarcodeInUse={checkBarcodeInUse}
                    setData={setExpressReferralFormData}
                    data={expressReferralFormData}
                    setIsScanning={setIsScanning}
                    isWorkplace
                />
            }*/}
            {/*{isScanning &&
                <BarcodeReader
                    handleClose={() => setIsScanning(false)}
                    handleCapture={e => setExpressReferralFormData({...expressReferralFormData, ["barcode"]: e})}
                />
            }*/}
            {/*            {isScanning &&
                <BarcodeScanner
                    handleCapture={e => setExpressReferralFormData({...expressReferralFormData, ["barcode"]: e})}
                    setIsScanning={setIsScanning}
                    checkBarcodeInUse={checkBarcodeInUse}
                />
            }*/}
            {isScanning &&
                <>
                    <Html5QrcodePlugin
                        fps={35}
                        qrbox={225}
                        qrCodeSuccessCallback={onNewScanResult}
                        isScanning={isScanning}
                    />
                    <div className={classes.qrCodeControlsWrapper}>
                        <Button variant="contained" className={classes.changeCameraBtn} onClick={handleShowCameraListClick}>
                            <img src="/images/basic-change-camera-outline.svg" height={26} width={26} style={{marginRight: 2}}/>
                            <span className={classes.changeCameraBtnText}>{getTranslation("generic_change_camera_button")}</span>
                        </Button>
                        {showCameraList &&
                            <div className={classes.camerasList}>
                                {cameras.map((c, index) => (
                                    <Typography variant="body2" className={classes.cameraListItem} style={{borderBottom: index === cameras.length - 1  ? "none" : "1px solid #CDCDCD"}} onClick={() => handleSelectBarcodeCamera(c)}>{c.label}</Typography>
                                ))}
                            </div>
                        }
                    </div>
                </>
            }
            <input
                type="file"
                name="id_photo"
                accept="image/*"
                capture="environment"
                ref={fileInputRef}
                onChange={handleCaptureIdPhoto}
                style={{display: "none"}}
            />
            <Grid item xs={12} md={12}>
                <Grid container className={classes.mainContainer}>
                    <Grid item xs={12} className={classes.row}>
                        <Button
                            className={classes.backButton}
                            startIcon={<KeyboardBackspaceIcon className={classes.backIcon}/>}
                            onClick={() => props.history.goBack()}
                        >
                            {getTranslation("testcase_button_go_back")}
                        </Button>
                    </Grid>
                    <Grid item xs={7} className={classes.row} style={{width: '100%'}}>
                        <Typography
                            variant="h5"><b>{testCaseData.organisation_name + `, ${testCaseData.title}`}</b></Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.row} style={{width: '100%'}}>
                        <Typography
                            variant="h5"
                            style={{textAlign: 'right', fontSize: '20px'}}
                        >
                            <b>{getTranslation("testcase_details_referral_counter_label")}</b> {expressReferralData.length}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.barcodeInputWrap}>
                            <TextField
                                variant="outlined"
                                name="barcode"
                                label={expressReferralFormData.barcode ? getTranslation("testcase_personal_enter_id_placeholder") : ""}
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutlineMobile
                                    }
                                }}
                                inputProps={{maxLength: 10}}
                                value={expressReferralFormData.barcode || ""}
                                className={clsx(classes.paddingLeft, classes.barcodeTextInputMobile)}
                                InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                                placeholder={getTranslation("testcase_personal_enter_id_placeholder")}
                                disabled={userData.have_been_tested === "1" || testCaseData.closed || barcodeIsValid === 'valid'}
                                onChange={(e) => {
                                    if (Number.isInteger(Number(e.target.value))) {
                                        handleTextChange(e);
                                    }
                                    if (e.target.value.length === 10) {
                                        checkBarcodeInUse(e.target.value);
                                    }
                                }}
                                error={!expressReferralFormData.barcode && !barcodeInUse && saveReferralClicked}
                                helperText={(!expressReferralFormData.barcode && saveReferralClicked) ? getTranslation('generic_error_required_field') : (barcodeInUse ? getTranslation("testcase_barcode_in_use") : "")}
                            />
                            <Button
                                style={{
                                    backgroundColor: colorConfig.green,
                                    marginTop: 9,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    maxHeight: 58,
                                }}
                                disabled={userData.have_been_tested === "1" || testCaseData.closed}
                                onClick={handleScanningOpen}
                            >
                                <CameraAltOutlinedIcon
                                    style={{fill: colorConfig.white, width: "36px", height: "36px"}}/>
                            </Button>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            style={{
                                backgroundColor: colorConfig.green,
                                marginTop: 9,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                maxHeight: 58,
                                display: 'flex',        // Ensures the contents are aligned in a row
                                alignItems: 'center',    // Vertically centers the contents
                            }}
                            disabled={userData.have_been_tested === "1" || testCaseData.closed}
                            onClick={() => handleFileInputClick()}
                        >
                            <CameraAltOutlinedIcon
                                style={{fill: colorConfig.white, width: "36px", height: "36px"}}/>
                            <span style={{marginLeft: '8px', color: colorConfig.white}}>Fotografera remiss</span>
                        </Button>
                    </Grid>

                    {/* {idOpen &&
                            <Grid item xs={12} style={{marginLeft: "-40px", marginTop: 20}}>
                                <CameraComp handleOnClick={handleCaptureIdPhoto} onClose={() => setIdOpen(false)}/>
                            </Grid>
                        }*/}

                    <Grid item xs={12}>
                        <Grid container justify="space-between" spacing={3}
                              style={{paddingTop: 22, paddingBottom: 22}}>
                            <Grid item xs={12}>
                                {userData.id_photo &&
                                    <div className={classes.imageLabel}>
                                        {getTranslation("testcase_personal_id_photo_label")}
                                    </div>
                                }
                                <div
                                    style={getPhotoDivStyle(expressReferralFormData.id_photo, testCaseData.closed, userData.have_been_tested)}
                                    onClick={() => {
                                        if (!expressReferralFormData.id_photo) {
                                            handleFileInputClick();
                                        }
                                    }}
                                >
                                    {(!expressReferralFormData.id_photo || expressReferralFormData.id_photo === "") &&
                                        <>
                                            <Typography variant="subtitle1"
                                                        className={classes.photoLabel}>{getTranslation("testcase_personal_id_photo_label")}</Typography>
                                            <CameraAltOutlinedIcon className={classes.cameraIcon}/>
                                        </>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justify="space-evenly">
                            <Grid item xs={12} className={classes.buttonWrap}>
                                <Button
                                    disabled={testCaseData.closed || barcodeInUse}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    onClick={createExpressReferral}
                                    style={{
                                        paddingBottom: 15,
                                        paddingTop: 15,
                                        textTransform: "uppercase",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {getTranslation("testcase_details_save_and_add")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.row} style={{width: '100%', marginTop: 30}}>
                        <Typography
                            variant="h5"><b>{getTranslation("testcase_details_generated_referrals_label")}</b></Typography>
                    </Grid>

                    {expressReferralData.length > 0 && expressReferralData.map((referral) =>
                        <Grid key={referral.express_referral_id} item xs={12}
                              style={{width: '100%', marginTop: 15, paddingRight: 4, paddingLeft: 4}}>
                            <Box className={classes.referralBox}>
                                <Typography variant="subtitle1">
                                    {referral.express_referral_id}
                                </Typography>
                                <DeleteOutlineOutlinedIcon
                                    className={classes.deleteIcon}
                                    onClick={() => handleDelete(referral)}
                                />
                            </Box>
                        </Grid>
                    )}

                    <Grid item xs={12} className={classes.bottomButtonsWrap}>
                        <Grid container justify="space-evenly">
                            {!testCaseData.closed &&
                                <React.Fragment>
                                    <Grid item xs={6} className={classes.buttonWrap}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => setIsPopupOpen(true)}
                                        >
                                            {getTranslation("testcase_details_close_button")}
                                        </Button>
                                    </Grid>
                                    {/* Confirmation Popup */}
                                    <ConfirmDeletePopup
                                        open={isPopupOpen}
                                        onClose={() => setIsPopupOpen(false)}
                                        onDelete={() => closeTestCase(id)}
                                    />
                                </React.Fragment>
                            }
                            {testCaseData.closed &&
                                <>
                                    <Grid item xs={12} className={classes.buttonWrap}>
                                        <Button
                                            className={classes.backButton}
                                            startIcon={<KeyboardBackspaceIcon className={classes.backIcon}/>}
                                            onClick={() => props.history.goBack()}
                                        >
                                            {getTranslation("testcase_button_go_back")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} className={classes.buttonWrap}>
                                        <Button
                                            disabled
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => closeTestCase(id)}
                                        >
                                            {getTranslation("testcase_details_case_closed_label")}
                                        </Button>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Mobile;
