import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Grid, MenuItem, Paper, Select, Typography, TextField, Box } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Webcam from "../../../components/webcam";
import { TestcaseContext } from "../../../context/testcaseContext";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import { FilterList } from "@material-ui/icons";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import LastPageIcon from "@material-ui/icons/LastPage";
import colorConfig from "../../../styles/Theme-settings";
import { apiServer } from "../../../functional/generateEnvUrls";
import { Html5Qrcode } from "html5-qrcode";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import { cameraConfig } from "../../../functional/barcodeConfig";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ConfirmDeletePopup from './confirmDeletePopup';
import BarcodeScanner from "../../../components/barcodeScanner";
import { Camera } from "react-camera-pro";
import CameraComp from "../../../components/cameraComp";

const Tablet = props => {
    const {
        getTranslation,
        userData,
        expressReferralData,
        deleteExpressReferral,
        setExpressReferralData,
        expressReferralFormData,
        setExpressReferralFormData,
        handleTextChange,
        checkBarcodeInUse,
        barcodeInUse,
        barcodeIsValid,
        barcodeOpen,
        setBarcodeOpen,
        getPhotoDivStyle,
        id,
        usersToTest,
        testCaseData,
        orgName,
        setUserData,
        rowsPerPage,
        page,
        filter,
        setFilter,
        filterModalOpen,
        setFilterModalOpen,
        fetchUsersToTest,
        createExpressReferral,
        closeTestCase,
        selectedOrg,
        sortBy,
        sortOrder,
        handleOrderChange,
        classes,
        rowsOptions,
        onUserClick,
        enqueueSnackbar,
        usersInfo,
        setIsPopupOpen,
        isPopupOpen,
        handleCaptureIdPhoto,
        isScanning,
        setIsScanning,
        saveReferralClicked,
    } = props;

    const {
        idOpen,
        setIdOpen,
        portraitOpen,
        setPortraitOpen,
    } = useContext(TestcaseContext);

    const openPDF = () => {
        if (testCaseData.tests_done > 0) {
            //let url = `${process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/testcase/${id}/pdf`;
            let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/testcase/${id}/pdf`;
            window.open(url, "_blank");
        } else {
            enqueueSnackbar(getTranslation("testcase_no_tests_to_print"), { variant: "error" });
        }
    }

    const resetFilter = () => {
        setFilter({})
        // fetchUsersToTest(true, 0, props.rowsPerPage);
    };
    const handleDelete = (referral) => {
        deleteExpressReferral(referral.express_referral_id);
    };

    return (
        <>
            {/*<Camera ref={cameraRef}/>*/}
            {/* <Webcam open={idOpen} handleClose={() => setIdOpen(false)}
                    handleCapture={e => setExpressReferralFormData({...expressReferralFormData, ["id_photo"]: e})}/>*/}
            <Grid item xs={12}>
                <Grid container className={classes.mainContainer}>
                    <Grid item xs={12} className={classes.row}>
                        <Button
                            className={classes.backButton}
                            startIcon={<KeyboardBackspaceIcon className={classes.backIcon} />}
                            onClick={() => props.history.goBack()}
                        >
                            {getTranslation("testcase_button_go_back")}
                        </Button>
                    </Grid>
                    <Grid item xs={7} className={classes.row}>
                        <Typography
                            variant="h5"><b>{testCaseData.organisation_name + `, ${testCaseData.title}`}</b></Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.row}>
                        <Typography
                            variant="h5"
                            style={{ textAlign: 'right', fontSize: '20px' }}
                        >
                            <b>{getTranslation("testcase_details_referral_counter_label")}</b> {expressReferralData.length}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.barcodeInputWrap}>
                            <TextField
                                variant="outlined"
                                name="barcode"
                                label={expressReferralFormData.barcode ? getTranslation("testcase_personal_enter_id_placeholder") : ""}
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                                inputProps={{ maxLength: 10 }}
                                value={expressReferralFormData.barcode || ""}
                                className={clsx(classes.paddingLeft, classes.barcodeTextInput)}
                                InputLabelProps={{ classes: { root: classes.textFieldInputLabel } }}
                                placeholder={getTranslation("testcase_personal_enter_id_placeholder")}
                                disabled={userData.have_been_tested === "1" || testCaseData.closed || barcodeIsValid === 'valid'}
                                onChange={(e) => {
                                    if (Number.isInteger(Number(e.target.value))) {
                                        handleTextChange(e);
                                    }
                                    if (e.target.value.length === 10) {
                                        checkBarcodeInUse(e.target.value);
                                    }
                                }}
                                error={!expressReferralFormData.barcode && !barcodeInUse && saveReferralClicked}
                                helperText={(!expressReferralFormData.barcode && saveReferralClicked) ? getTranslation('generic_error_required_field') : (barcodeInUse ? getTranslation("testcase_barcode_in_use") : "")}
                            />
                            {/*<Button
                                style={{
                                    backgroundColor: colorConfig.green,
                                    marginTop: 9,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    maxHeight: 58
                                }}
                                disabled={userData.have_been_tested === "1" || testCaseData.closed}
                                onClick={() => setIsScanning(true)}
                            >
                                <CameraAltOutlinedIcon
                                    style={{ fill: colorConfig.white, width: "36px", height: "36px" }} />
                            </Button>*/}
                        </div>
                    </Grid>

                    {isScanning &&
                        <BarcodeScanner
                            checkBarcodeInUse={checkBarcodeInUse}
                            setData={setExpressReferralFormData}
                            data={expressReferralFormData}
                            setIsScanning={setIsScanning}
                            isWorkplace
                        />
                    }

                    <Grid item xs={12}>
                        <Button
                            style={{
                                backgroundColor: colorConfig.green,
                                marginTop: 9,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                maxHeight: 58,
                                display: 'flex',        // Ensures the contents are aligned in a row
                                alignItems: 'center',    // Vertically centers the contents
                            }}
                            disabled={userData.have_been_tested === "1" || testCaseData.closed}
                            onClick={() => setIdOpen(true)}
                        >
                            <CameraAltOutlinedIcon style={{ fill: colorConfig.white, width: "36px", height: "36px" }} />
                            <span style={{ marginLeft: '8px', color: colorConfig.white }}>Fotografera remiss</span>
                        </Button>
                    </Grid>

                    {idOpen &&
                        <Grid item xs={12} style={{ marginLeft: "-40px", marginTop: 20 }}>
                            <CameraComp handleOnClick={handleCaptureIdPhoto} onClose={() => setIdOpen(false)} />
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <Grid container justify="space-between" spacing={3}
                            style={{ paddingTop: 22, paddingBottom: 22 }}>
                            <Grid item xs={12}>
                                {userData.id_photo &&
                                    <div className={classes.imageLabel}>
                                        {getTranslation("testcase_personal_id_photo_label")}
                                    </div>
                                }
                                <div
                                    style={getPhotoDivStyle(expressReferralFormData.id_photo, testCaseData.closed, userData.have_been_tested)}
                                    onClick={() => setIdOpen(true)}
                                >
                                    {(!expressReferralFormData.id_photo || expressReferralFormData.id_photo === "") &&
                                        <>
                                            <Typography variant="subtitle1"
                                                className={classes.photoLabel}>{getTranslation("testcase_personal_id_photo_label")}</Typography>
                                            <CameraAltOutlinedIcon className={classes.cameraIcon} />
                                        </>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.bottomButtonsWrap}>
                        <Grid container justify="space-evenly">
                            <Grid item xs={12} className={classes.buttonWrap}>
                                <Button
                                    disabled={testCaseData.closed || barcodeInUse}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    onClick={createExpressReferral}
                                    style={{
                                        paddingBottom: 15,
                                        paddingTop: 15,
                                        textTransform: "uppercase",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {getTranslation("testcase_details_save_and_add")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.row} style={{ width: '100%', marginTop: 30 }}>
                        <Typography
                            variant="h5"><b>{getTranslation("testcase_details_generated_referrals_label")}</b></Typography>
                    </Grid>

                    {expressReferralData.length > 0 && expressReferralData.map((referral) =>
                        <Grid key={referral.express_referral_id} item xs={3}
                            style={{ width: '100%', marginTop: 15, paddingRight: 4, paddingLeft: 4 }}>
                            <Box className={classes.referralBox}>
                                <Typography variant="subtitle1">
                                    {referral.express_referral_id}
                                </Typography>
                                <DeleteOutlineOutlinedIcon
                                    className={classes.deleteIcon}
                                    onClick={() => handleDelete(referral)}
                                />
                            </Box>
                        </Grid>
                    )}

                    <Grid item xs={12} className={classes.bottomButtonsWrap}>
                        <Grid container justify="space-evenly">
                            {!testCaseData.closed &&
                                <React.Fragment>
                                    <Grid item xs={6} className={classes.buttonWrap}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => setIsPopupOpen(true)}
                                        >
                                            {getTranslation("testcase_details_close_button")}
                                        </Button>
                                    </Grid>
                                    {/* Confirmation Popup */}
                                    <ConfirmDeletePopup
                                        open={isPopupOpen}
                                        onClose={() => setIsPopupOpen(false)}
                                        onDelete={() => closeTestCase(id)}
                                    />
                                </React.Fragment>
                            }
                            {testCaseData.closed &&
                                <>
                                    <Grid item xs={12} className={classes.buttonWrap}>
                                        <Button
                                            className={classes.backButton}
                                            startIcon={<KeyboardBackspaceIcon className={classes.backIcon} />}
                                            onClick={() => props.history.goBack()}
                                        >
                                            {getTranslation("testcase_button_go_back")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} className={classes.buttonWrap}>
                                        <Button
                                            disabled
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => closeTestCase(id)}
                                        >
                                            {getTranslation("testcase_details_case_closed_label")}
                                        </Button>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Tablet;
