// eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const generatePreviewImgUrl = (file, callback) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onloadend = e => callback(reader.result)
}
export const permLevel = (params) => {
  if (!params) {
    return 0;
  }
  if (params.type === 1 && params.perm === 1) {
    return 4;
  } else if (params.type === 1 && params.perm === 2) {
    return 3;
  } else if (params.type === 2 && params.perm === 1) {
    return 2;
  } else {
    return 1;
  }
}

export const countries = [
  {
    title: "SE",
    value: "se",
  },
  {
    title: "FI",
    value: "fi",
  },
  {
    title: "DK",
    value: "dk",
  }
];

export const isSafariBrowser = function () {
  let sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf("Safari") !== -1 && sUsrAg.indexOf("Chrome") === -1)
    return true;
  return false;
};

export const isEdgeBrowser = function () {
  let sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf("Edge") > -1) return true;
  return false;
};

export function toScrollTop() {
  if (isSafariBrowser() || isEdgeBrowser()) {
    let pos = window.pageYOffset;
    let timerId = setInterval(() => {
      if (pos <= 0) clearInterval(timerId);
      window.scrollBy(0, -120);
      pos -= 120;
    }, 1);
  } else {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}